<template>
  <!-- 頁面組件: (權限: 完成, 註解: 刪除, 優化: 無) -->
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <base-view />
        <vaild-view />
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <payment-view
          ref="paymentView"
          :branch-data-item="adminBranchState.branchInfo"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BaseView from './components/BaseView.vue'
import VaildView from './components/VaildView.vue'
import PaymentView from './components/PaymentView.vue'

export default {
  components: {
    BRow,
    BCol,
    BaseView,
    VaildView,
    PaymentView,
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
}
</script>
