<template>
  <div class="branch-view-payment">
    <b-overlay
      :show="isBusy"
      variant="transparent"
      opacity="0.85"
      rounded="sm"
      blur="5px"
    >
      <b-card>
        <div class="d-flex justify-content-between align-items-center mb-50">
          <h4>金流設定</h4>

          <div class="text-nowrap d-flex justify-content-end">
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="重新整理"
              class="d-flex align-items-center actions-link-btn ml-25"
              @click="submitResetBranchPaymentData"
            >
              <b-img
                src="/dashboard/admin/images/table/refresh.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-b-tooltip.hover.focus.v-secondary
              title="儲存"
              class="d-flex align-items-center actions-link-btn ml-25"
              @click="submitBranchUpdate"
            >
              <b-img
                src="/dashboard/admin/images/table/save.svg"
                class="actions-link-btn-image"
              />
            </div>
          </div>
        </div>

        <b-row>
          <b-col
            cols="12"
            md="5"
          >
            <div class="payment-area">
              <div
                v-for="type in typeOptions"
                :key="type.value"
                class="mb-2"
              >
                <div class="d-flex justify-content-start align-items-center">
                  <div>
                    <b-img
                      :src="require(`@/assets/images/pages/payment/${type.value}.svg`)"
                      height="35"
                      rounded
                    />
                  </div>

                  <div class="ml-1">
                    <h5 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                      {{ type.label }}
                    </h5>
                  </div>
                </div>

                <div class="mt-1 component-list border">
                  <draggable
                    v-if="!isBranchBusy"
                    tag="div"
                    :group="type.value"
                    :list="branchPaymentData[type.value]"
                    class="list-group list-group-flush cursor-move"
                    :empty-insert-threshold="300"
                    style="min-height: 50px;"
                    handle=".draggable-handle"
                  >
                    <div
                      v-for="(item, index) in branchPaymentData[type.value]"
                      :key="index"
                      class="component-area mb-1"
                    >
                      <div class="d-flex justify-content-between align-items-center component-area-content draggable-handle">
                        <div class="d-flex justify-content-between align-items-center">
                          <b-img
                            :src="require('@/assets/images/pages/branch/header-manu.svg')"
                            blank-color="#ccc"
                            width="16"
                            :alt="item.type"
                            class="mr-1"
                          />
                          <div>
                            <div class="item-description font-weight-bold d-block text-nowrap">
                              {{ item.name }}
                            </div>

                            <small v-if="item.third_party_payment">
                              <div class="item-description text-muted">{{ item.third_party_payment.name }}</div>
                            </small>
                          </div>
                        </div>

                        <div class="d-flex align-items-center">
                          <div
                            class="actions-link-btn-sm"
                            @click="submitBranchPaymentRemove(type.value, item)"
                          >
                            <b-img
                              v-b-tooltip.hover.focus.v-secondary
                              title="移除"
                              src="/dashboard/admin/images/table/swap.svg"
                              class="actions-link-btn-image"
                              rounded
                            />
                          </div>
                        </div>
                      </div>

                      <hr>

                      <div class="component-area-footer">
                        <div class="text-right">
                          <feather-icon
                            v-b-toggle="`collapse-list-${type.value}-${index}`"
                            icon="ChevronDownIcon"
                            size="12"
                          />
                        </div>

                        <b-collapse
                          :id="`collapse-list-${type.value}-${index}`"
                          class="mt-50"
                        >
                          <b-card no-body>
                            <b-table-simple
                              striped
                              class="table-sm"
                            >
                              <b-tr>
                                <b-td class="text-right align-top text-nowrap py-75">
                                  驅動：
                                </b-td>
                                <b-td
                                  class="py-75"
                                  style="min-width: 100px;"
                                >
                                  <span v-if="item.third_party_payment">{{ item.third_party_payment.provider }}</span>
                                  <span v-else>---</span>
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="text-right align-top text-nowrap py-75">
                                  單筆上限：
                                </b-td>
                                <b-td class="py-75">
                                  <span v-if="item.low">$ {{ parseInt(item.high, 10).toLocaleString() }}</span>
                                  <span v-else>---</span>
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="text-right align-top text-nowrap py-75">
                                  單筆下限：
                                </b-td>
                                <b-td class="py-75">
                                  <span v-if="item.low">$ {{ parseInt(item.low, 10).toLocaleString() }}</span>
                                  <span v-else>---</span>
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="text-right align-top text-nowrap py-75">
                                  單日上限：
                                </b-td>
                                <b-td class="py-75">
                                  <span v-if="item.low">$ {{ parseInt(item.daily, 10).toLocaleString() }}</span>
                                  <span v-else>---</span>
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="text-right align-top text-nowrap py-75">
                                  單月上限：
                                </b-td>
                                <b-td class="py-75">
                                  <span v-if="item.low">$ {{ parseInt(item.monthly, 10).toLocaleString() }}</span>
                                  <span v-else>---</span>
                                </b-td>
                              </b-tr>
                            </b-table-simple>
                          </b-card>
                        </b-collapse>
                      </div>
                    </div>

                    <div
                      v-if="branchPaymentData[type.value].length === 0"
                    >
                      <div class="text-center my-2 animate__animated animate__fadeIn">
                        <b-img
                          :src="$store.state.app.themeImages.notFoundImg"
                          fluid
                          width="300"
                          alt="查無資料"
                        />
                      </div>
                    </div>
                  </draggable>

                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center my-2"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.loadingImg"
                      rounded
                      height="40"
                      width="40"
                    />
                  </div>
                </div>
              </div>

              <draggable
                tag="div"
                group="payment"
                class="list-group list-group-flush cursor-move payment-area-draggable"
                handle=".draggable-handle"
                @add="dragBranchPaymentAdd($event)"
              />
            </div>
          </b-col>

          <!-- 箭頭 -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center flex-md-column justify-content-center mb-2"
          >
            <div class="mb-50 my-1">
              <feather-icon
                icon="ArrowRightIcon"
                class="cursor-pointer text-muted d-md-block d-none"
                size="16"
              />

              <feather-icon
                icon="ArrowUpIcon"
                class="cursor-pointer text-muted d-md-none d-block"
                size="16"
              />
            </div>

            <div class="mb-50 my-1">
              <feather-icon
                icon="ArrowLeftIcon"
                class="cursor-pointer text-muted d-md-block d-none"
                size="16"
              />

              <feather-icon
                icon="ArrowDownIcon"
                class="cursor-pointer text-muted d-md-none d-block"
                size="16"
              />
            </div>
          </b-col>

          <!-- 金流包 -->
          <b-col
            cols="12"
            md="5"
            class="mb-2"
          >
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <b-img
                  :src="require('@/assets/images/pages/branch/money-bag.svg')"
                  height="35"
                  rounded
                />
              </div>

              <div class="ml-1">
                <h5 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                  金流包
                </h5>
              </div>
            </div>

            <div class="component-list border my-1 component-list-payment">
              <draggable
                v-if="!isPaymentBusy"
                :list="paymentOptionsFilter"
                tag="div"
                :group="draggableSetting"
                class="list-group list-group-flush cursor-move"
                :empty-insert-threshold="300"
                style="min-height: 300px;"
                handle=".draggable-handle"
                @add="dragPaymentRemove($event)"
              >
                <div
                  v-for="(item, index) in paymentOptionsFilter"
                  :key="index"
                  class="component-area mb-1"
                >
                  <div class="d-flex justify-content-between align-items-center component-area-content draggable-handle">
                    <div class="d-flex justify-content-between align-items-center">
                      <b-img
                        :src="require(`@/assets/images/pages/payment/${item.type}.svg`)"
                        blank-color="#ccc"
                        width="28"
                        :alt="item.type"
                        class="mr-50"
                      />
                      <div>
                        <div class="item-description font-weight-bold d-block text-nowrap">
                          {{ item.name }}
                          <!-- / {{ item.id }} -->
                        </div>

                        <small v-if="item.third_party_payment">
                          <div class="item-description text-muted">{{ item.third_party_payment.name }}</div>
                        </small>
                      </div>
                    </div>

                    <!-- <div
                      v-if="item.third_party_payment"
                      class=""
                    >
                      <span class="mr-50 text-nowrap">{{ item.third_party_payment.provider }}</span>
                    </div> -->

                    <div class="d-flex align-items-center">
                      <div
                        class="actions-link-btn-sm"
                        @click="submitPaymentAdd(item)"
                      >
                        <b-img
                          v-b-tooltip.hover.focus.v-secondary
                          title="新增"
                          src="/dashboard/admin/images/table/swap.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="component-area-footer">
                    <div class="text-right">
                      <feather-icon
                        v-b-toggle="`collapse-list-${index}`"
                        icon="ChevronDownIcon"
                        size="12"
                      />
                    </div>

                    <b-collapse
                      :id="`collapse-list-${index}`"
                      class="mt-50"
                    >
                      <b-card no-body>
                        <b-table-simple
                          striped
                          class="table-sm"
                        >
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              驅動：
                            </b-td>
                            <b-td
                              class="py-75"
                              style="min-width: 100px;"
                            >
                              <span v-if="item.third_party_payment">{{ item.third_party_payment.provider }}</span>
                              <span v-else>---</span>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              單筆上限：
                            </b-td>
                            <b-td class="py-75">
                              <span v-if="item.low">$ {{ parseInt(item.high, 10).toLocaleString() }}</span>
                              <span v-else>---</span>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              單筆下限：
                            </b-td>
                            <b-td class="py-75">
                              <span v-if="item.low">$ {{ parseInt(item.low, 10).toLocaleString() }}</span>
                              <span v-else>---</span>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              單日上限：
                            </b-td>
                            <b-td class="py-75">
                              <span v-if="item.low">$ {{ parseInt(item.daily, 10).toLocaleString() }}</span>
                              <span v-else>---</span>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              單月上限：
                            </b-td>
                            <b-td class="py-75">
                              <span v-if="item.low">$ {{ parseInt(item.monthly, 10).toLocaleString() }}</span>
                              <span v-else>---</span>
                            </b-td>
                          </b-tr>

                          <!-- <b-tr>
                            <b-td class="text-right align-center text-nowrap py-75">
                              動作：
                            </b-td>
                            <td class="py-75">
                              <div
                                class="d-flex"
                                style="min-width: 100px;"
                              >
                                <div
                                  class="actions-link-btn-sm mr-25"
                                  @click="submitPaymentAdd(item)"
                                >
                                  <b-img
                                    v-b-tooltip.hover.focus.v-secondary
                                    title="新增"
                                    src="/dashboard/admin/images/table/swap.svg"
                                    class="actions-link-btn-image"
                                    rounded
                                  />
                                </div>
                              </div>
                            </td>
                          </b-tr> -->
                        </b-table-simple>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>

                <div
                  v-if="paymentOptionsFilter.length === 0"
                  class="loading-area"
                >
                  <div class="text-center my-5 animate__animated animate__fadeIn">
                    <b-img
                      :src="$store.state.app.themeImages.notFoundImg"
                      fluid
                      width="480"
                      alt="查無資料"
                    />
                  </div>
                </div>
              </draggable>

              <div
                v-else
                class="d-flex align-items-center justify-content-center my-2 h-100"
              >
                <b-img
                  :src="$store.state.app.themeImages.loadingImg"
                  rounded
                  height="40"
                  width="40"
                />
              </div>
            </div>

            <!-- 顯示頁數/分頁 -->
            <div class="mx-2 mb-2">
              <b-row>
                <!-- 顯示頁數 -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
                </b-col>

                <!-- 分頁 -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalNum"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- {{ branchPaymentData }}
      {{ branchData.payment }} -->
      <!-- {{ paymentOptions }} -->
    </b-overlay>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BImg, VBTooltip, BTableSimple, BTr, BTd, VBToggle, BCollapse, BOverlay, BPagination,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { useBranchView } from '../../useBranch'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BTableSimple,
    BPagination,
    BCollapse,
    BTr,
    BTd,
    BOverlay,

    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    branchDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      draggableSetting: { name: 'payment', pull: true, put: ['credit', 'store', 'account'] },
    }
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
    paymentOptionsFilter() {
      return this.paymentOptions.filter(item => !this.branchData.payment.includes(item.id) && item.state)
    },
  },
  methods: {
    // (初始化)
    initPaymentInfoList() {
      this.resetBranchData()
      this.resetBranchPaymentData()
      this.branchData.payment_info.forEach(item => {
        const paymentInfo = item
        if (paymentInfo) {
          if (paymentInfo.type === 'account') {
            this.branchPaymentData.account.push(paymentInfo)
          } else if (paymentInfo.type === 'store') {
            this.branchPaymentData.store.push(paymentInfo)
          } else if (paymentInfo.type === 'credit') {
            this.branchPaymentData.credit.push(paymentInfo)
          }
        }
      })
      this.isBranchBusy = false
    },

    // (重設)
    submitResetBranchPaymentData() {
      this.$emit('refresh-payment-setting')
      this.isBranchBusy = true
      this.currentPage = 1
    },

    // (新增)金流設定(拖曳)
    dragBranchPaymentAdd(e) {
      const paymentOptionsFilter = this.paymentOptions.filter(item => !this.branchData.payment.includes(item.id) && item.state)
      const newComponent = paymentOptionsFilter[e.oldDraggableIndex]

      if (newComponent) {
        this.branchData.payment.push(newComponent.id)
        if (newComponent.type === 'account') {
          this.branchPaymentData.account.push(newComponent)
        } else if (newComponent.type === 'store') {
          this.branchPaymentData.store.push(newComponent)
        } else if (newComponent.type === 'credit') {
          this.branchPaymentData.credit.push(newComponent)
        }
      }
    },

    // (移除)金流設定(拖曳)
    dragPaymentRemove(e) {
      const newComponent = this.paymentOptionsFilter[e.newDraggableIndex]
      const index = this.branchData.payment.findIndex(item => item === newComponent.id)
      if (index !== -1) {
        this.branchData.payment.splice(index, 1)
      }
    },

    // (新增)金流設定
    submitPaymentAdd(item) {
      this.branchData.payment.push(item.id)
      this.branchPaymentData[item.type].push(item)
    },

    // (移除)金流設定
    submitBranchPaymentRemove(type, item) {
      const index1 = this.branchData.payment.findIndex(el => el === item.id)
      if (index1 !== -1) {
        this.branchData.payment.splice(index1, 1)
      }
      const index2 = this.branchPaymentData[type].findIndex(el => el.id === item.id)
      if (index2 !== -1) {
        this.branchPaymentData[type].splice(index2, 1)
      }
    },

    // (更新)金流設定
    submitBranchUpdate() {
      if (this.isBusy) return
      this.isBusy = true

      const resolveBranchPayment = [
        ...this.branchPaymentData.account,
        ...this.branchPaymentData.store,
        ...this.branchPaymentData.credit,
      ].map(item => item.id)

      const resolveString = resolveBranchPayment.join(',')

      const resolveData = {
        branch_id: this.branchDataItem.id,
        data: {
          payment: resolveString,
        },
      }
      this.setBranchUpdate(resolveData, state => {
        if (state) this.useAlertToast(true, '更新分站成功')
        this.isBusy = false
      })
    },
  },
  setup(props) {
    const {
      isBusy,
      paymentOptions,
      blnkPaymentData,
      blankPaymentType,
      syncObject,
      setBranchUpdate,
      useAlertToast,
    } = useBranchView()

    const blankBranchData = {
      payment: [],
      payment_info: [],
    }
    const blankBranchPaymentData = {
      account: [],
      store: [],
      credit: [],
    }

    const typeOptions = [{
      label: '虛擬帳號', value: 'account',
    }, {
      label: '信用卡', value: 'credit',
    }, {
      label: '超商代碼', value: 'store',
    }]

    const branchData = ref(JSON.parse(JSON.stringify(blankBranchData)))
    const branchPaymentData = ref(JSON.parse(JSON.stringify(blankBranchPaymentData)))

    const isBranchBusy = ref(true)
    const isPaymentBusy = ref(true)

    const resetBranchData = () => {
      const resolveBranchInfo = syncObject(blankBranchData, props.branchDataItem)
      branchData.value = JSON.parse(JSON.stringify(resolveBranchInfo))
      isBusy.value = false
    }

    const resetBranchPaymentData = () => {
      branchPaymentData.value = JSON.parse(JSON.stringify(blankBranchPaymentData))
    }

    // -------------------------------------金流列表------------------------------------------------
    const perPage = ref(5)
    const totalNum = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref('')

    const dataMeta = computed(() => {
      const localItemsCount = totalNum.value
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
        of: totalNum.value,
      }
    })

    const getPaymentListData = () => {
      isPaymentBusy.value = true
      store.dispatch('admin-payment-modal/getPaymentList', {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: 'id',
        order: 'desc',
        offset: dataMeta.value.from,
        _: Date.now(),
      })
        .then(response => {
          const { data, total } = response.data.data
          const paymentListOptions = data.map(item => {
            const resolve = {
              ...syncObject(blnkPaymentData, item),
              third_party_payment: syncObject(blankPaymentType, item.third_party_payment ? item.third_party_payment : {}),
            }
            return resolve
          })
          paymentOptions.value = paymentListOptions
          totalNum.value = total
          isPaymentBusy.value = false
        })
    }

    watch([currentPage, searchQuery], () => {
      getPaymentListData()
    })

    getPaymentListData()

    return {
      isBranchBusy,
      isPaymentBusy,
      branchData,
      branchPaymentData,
      useAlertToast,
      isBusy,
      paymentOptions,
      typeOptions,
      setBranchUpdate,
      resetBranchData,
      resetBranchPaymentData,
      getPaymentListData,

      perPage,
      totalNum,
      currentPage,
      searchQuery,
      dataMeta,
    }
  },
}
</script>

<style lang="scss" scoped>
.branch-view-payment {
  .actions-link-btn-image {
    width: 20px;
    height: 20px;
  }

  .component-list {
    padding: 15px;
    border-radius: 10px;
  }

  .component-list-payment {
    overflow-y: scroll;
    height: 65vh;
  }

  .component-area {
    background-color: #b5bfd634;
    border-radius: 10px;
    .component-area-content {
      padding: 15px;
    }

    hr {
      border-color: #cfcfcf57;
      margin: 0;
    }

    .component-area-footer {
      padding: 5px 15px;
      cursor: auto;
    }
  }

  .payment-area {
    position: relative;
    .payment-area-draggable {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
