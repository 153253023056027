<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          基本設定
        </h4>

        <div class="text-nowrap d-flex justify-content-end">
          <div
            v-b-tooltip.hover.focus.v-secondary
            title="重新整理"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="resetBranchData"
          >
            <b-img
              src="/dashboard/admin/images/table/refresh.svg"
              class="actions-link-btn-image"
            />
          </div>

          <div
            v-b-tooltip.hover.focus.v-secondary
            title="儲存"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="submitBranchUpdate"
          >
            <b-img
              src="/dashboard/admin/images/table/save.svg"
              class="actions-link-btn-image"
            />
          </div>
        </div>
      </b-card-header>

      <b-card-body>
        <div class="ml-50">
          <!-- 分站名稱 -->
          <b-form-group
            label="分站名稱"
            label-for="name"
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            class="edit-col-from-group"
          >
            <b-input-group
              v-if="editCol === 'name'"
              class="input-group-merge"
            >
              <b-form-input
                id="name"
                v-model="branchData.name"
                :placeholder="editColData ? editColData : '請輸入分站名稱'"
              />

              <b-input-group-append is-text>
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                    width="18"
                    class="mr-75 cursor-pointer"
                    @click="confirmEditCol"
                  />

                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                    width="18"
                    class="cursor-pointer"
                    @click="cancelEditCol"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>

            <div
              v-else
              class="edit-col-label"
              @click="selectEditCol('name')"
            >
              <span>
                {{ branchData.name ? branchData.name : '---' }}
              </span>

              <span class="selection-btn-icon">
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                  height="15"
                  width="15"
                  class="cursor-pointer"
                />
              </span>
            </div>
          </b-form-group>

          <!-- 分站代號 -->
          <b-form-group
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            label="代號"
            class="mb-25 preview-col-from-group"
          >
            <div class="mt-50 preview-col-label">
              {{ branchDataItem.branch }}
            </div>
          </b-form-group>

          <!-- 網域 -->
          <b-form-group
            label="網域"
            label-for="domain"
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            class="edit-col-from-group"
          >
            <b-input-group
              v-if="editCol === 'domain'"
              class="input-group-merge"
            >
              <b-form-input
                id="domain"
                v-model="branchData.domain"
                :placeholder="editColData ? editColData : '請輸入分站名稱'"
              />

              <b-input-group-append is-text>
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                    width="18"
                    class="mr-75 cursor-pointer"
                    @click="confirmEditCol"
                  />

                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                    width="18"
                    class="cursor-pointer"
                    @click="cancelEditCol"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>

            <div
              v-else
              class="edit-col-label"
              @click="selectEditCol('domain')"
            >
              <span>
                {{ branchData.domain ? branchData.domain : '---' }}
              </span>

              <span class="selection-btn-icon">
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                  height="15"
                  width="15"
                  class="cursor-pointer"
                />
              </span>
            </div>
          </b-form-group>

          <!-- 訂單代號 -->
          <b-form-group
            label="訂單代號"
            label-for="prefix"
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            class="edit-col-from-group"
          >
            <b-input-group
              v-if="editCol === 'prefix'"
              class="input-group-merge"
            >
              <b-form-input
                id="prefix"
                v-model="branchData.prefix"
                :placeholder="editColData ? editColData : '請輸入訂單代號'"
              />

              <b-input-group-append is-text>
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                    width="18"
                    class="mr-75 cursor-pointer"
                    @click="confirmEditCol"
                  />

                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                    width="18"
                    class="cursor-pointer"
                    @click="cancelEditCol"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>

            <div
              v-else
              class="edit-col-label"
              @click="selectEditCol('prefix')"
            >
              <span>
                {{ branchData.prefix ? branchData.prefix : '---' }}
              </span>

              <span class="selection-btn-icon">
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                  height="15"
                  width="15"
                  class="cursor-pointer"
                />
              </span>
            </div>
          </b-form-group>

          <!-- 說明 -->
          <b-form-group
            label="說明"
            label-for="introduce"
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            class="edit-col-from-group"
          >
            <b-input-group
              v-if="editCol === 'introduce'"
              class="input-group-merge"
            >
              <b-form-input
                id="introduce"
                v-model="branchData.introduce"
                :placeholder="editColData ? editColData : '請輸入分站說明'"
              />

              <b-input-group-append is-text>
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                    width="18"
                    class="mr-75 cursor-pointer"
                    @click="confirmEditCol"
                  />

                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                    width="18"
                    class="cursor-pointer"
                    @click="cancelEditCol"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>

            <div
              v-else
              class="edit-col-label"
              @click="selectEditCol('introduce')"
            >
              <span>
                {{ branchData.introduce ? branchData.introduce : '---' }}
              </span>

              <span class="selection-btn-icon">
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                  height="15"
                  width="15"
                  class="cursor-pointer"
                />
              </span>
            </div>
          </b-form-group>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BImg, VBTooltip, BCardHeader, BCardBody, BFormInput, BInputGroup, BInputGroupAppend, BFormGroup,
} from 'bootstrap-vue'
import { useBranchSetting, useBranchView } from '../../useBranch'

export default {
  components: {
    BCard,
    BImg,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    branchDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editCol: null,
      editColData: null,
      missingFields: [],
      maxChar: 255,
    }
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
  methods: {
    // (選取)編輯欄位
    selectEditCol(type) {
      this.editCol = type
      this.editColData = this.branchData[type]
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.branchData[this.editCol] = this.editColData
      this.editCol = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      this.editCol = null
    },

    submitBranchUpdate() {
      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '分站名稱',
        domain: '網域',
        prefix: '訂單代號',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.branchData[Object.keys(requiredFields)[i]] === null || this.branchData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.branchData.introduce.length > this.maxChar) {
        this.useAlertToast(false, '分站說明字數超過上限')
        return
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true

      const resolveData = {
        branch_id: this.branchDataItem.id,
        data: {
          ...this.branchData,
        },
      }

      this.setBranchUpdate(resolveData, state => {
        if (state) this.useAlertToast(true, '更新分站成功')
        this.isBusy = false
      })
    },
  },
  setup(props) {
    const {
      ui,
      syncObject,
    } = useBranchSetting()

    const {
      isBusy,
      setBranchUpdate,
      useAlertToast,
    } = useBranchView()

    const blankBranchData = {
      name: null,
      domain: null,
      prefix: null,
      introduce: '',
    }

    const branchData = ref(JSON.parse(JSON.stringify(blankBranchData)))

    const resetBranchData = () => {
      if (isBusy.value) return
      const resolveBranchInfo = syncObject(blankBranchData, props.branchDataItem)
      branchData.value = JSON.parse(JSON.stringify(resolveBranchInfo))
      isBusy.value = false
    }

    resetBranchData()

    return {
      ui,
      branchData,
      isBusy,
      setBranchUpdate,
      resetBranchData,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss" scoped>
.edit-col-from-group {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  .edit-col-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    word-break: break-all;
    overflow: hidden;
    .selection-btn-icon {
      display: none;
    }
    &:hover {
      background: #a9a8a820;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 36px;
      .selection-btn-icon {
        display: inline-block;
      }
    }
  }

  .edit-col-select {
    width: calc(100% - 80px);
  }
}

.preview-col-from-group {
  .preview-col-label {
    padding: 0 10px;
  }
}
</style>
