<template>
  <div v-if="adminBranchState">
    <payment-setting
      ref="paymentSetting"
      :branch-data-item="adminBranchState.branchInfo"
      @refresh-payment-setting="initPaymentSettingList"
    />
  </div>
</template>

<script>
import PaymentSetting from './components/PaymentSetting.vue'

export default {
  components: {
    PaymentSetting,
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
  watch: {
    'adminBranchState.branchInfo': {
      handler() {
        this.initPaymentSettingList()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminBranchState.branchInfo) {
      this.initPaymentSettingList()
    }
  },
  methods: {
    // (初始化)金流
    initPaymentSettingList() {
      setTimeout(() => {
        if (this.adminBranchState.branchTab === 2) this.$refs.paymentSetting.initPaymentInfoList()
      }, 100)
    },
  },
  setup() {
    return {
    }
  },
}
</script>
