<template>
  <!-- 頁面組件: (權限: 完成, 註解: 刪除, 優化: 無) -->
  <div>
    <b-skeleton-wrapper :loading="adminBranchState.isBusyLoading">
      <template #loading>
        <b-card
          class="profile-header mb-2"
          alt="cover photo"
          body-class="p-0"
        >
          <div class="profile-header-banner" />

          <div class="position-relative">
            <div class="profile-img-container d-flex align-items-center">
              <div class="profile-img">
                <b-skeleton-img
                  aspect="1:1"
                  alt="加載圖片"
                />
              </div>
              <div class="profile-title ml-2 mb-2">
                <h2>
                  loading...
                </h2>
              </div>
            </div>
          </div>

          <div class="profile-header-nav text-right">
            <b-navbar class="p-2" />
          </div>
        </b-card>
      </template>
    </b-skeleton-wrapper>

    <b-card
      v-if="!adminBranchState.isBusyLoading"
      class="profile-header mb-2"
      no-body
    >
      <div class="profile-header-banner">
        <VueLoadImage
          v-if="adminBranchState.branchInfo.banner"
          :use-loading="true"
          :use-failed="true"
          :blank-height="'300px'"
          :loading-width="60"
          :loading-height="60"
          :failed-width="100"
        >
          <b-card-img
            slot="image"
            :src="adminBranchState.branchInfo.banner"
            alt="分站形象"
            fluid
            class="rounded-0 banner-image"
          />
        </VueLoadImage>

        <div
          class="banner-image-mask animate__animated animate__fadeIn"
        >
          <div
            v-if="checkAuthAbility('branch', 'API.Admin.Branch.Banner')"
            class="banner-icon banner-image-edit"
            @click="submitUploadImage('banner')"
          >
            <b-img
              src="/dashboard/admin/images/table/edit-w.svg"
              width="16"
              height="16"
            />
          </div>

          <div
            v-if="adminBranchState.branchInfo.banner && checkAuthAbility('branch', 'API.Admin.Branch.Banner.Clear')"
            class="banner-icon banner-image-reset"
            @click="removeImage('banner')"
          >
            <b-img
              src="/dashboard/admin/images/table/refresh-w.svg"
              width="16"
              height="16"
            />
          </div>
        </div>
      </div>

      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <VueLoadImage
              v-if="adminBranchState.branchInfo.icon"
              :use-loading="true"
              :use-failed="true"
              :blank-height="'120px'"
              :loading-width="30"
              :loading-height="30"
              :failed-width="60"
            >
              <b-img
                slot="image"
                :src="adminBranchState.branchInfo.icon"
                rounded
                fluid-grow
                alt="分站LOGO"
                class="icon-iamge"
              />
            </VueLoadImage>

            <b-img
              v-else
              :src="$store.state.app.themeImages.emptyImg"
              rounded
              fluid
              alt="分站LOGO"
            />

            <div
              class="animate__animated animate__fadeIn"
              :class="{ 'profile-image-mask': checkAuthAbility('branch', 'API.Admin.Branch.Icon')}"
            >
              <div
                v-if="checkAuthAbility('branch', 'API.Admin.Branch.Icon')"
                class="profile-icon profile-image-edit"
                @click="submitUploadImage('icon')"
              >
                <b-img
                  src="/dashboard/admin/images/table/edit-w.svg"
                  width="30"
                  height="30"
                />
              </div>
              <div
                v-if="adminBranchState.branchInfo.icon && checkAuthAbility('branch', 'API.Admin.Branch.Icon.Clear')"
                class="profile-icon profile-image-reset"
                @click="removeImage('icon')"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh-w.svg"
                  width="16"
                  height="16"
                />
              </div>
            </div>
          </div>
          <div class="profile-title ml-2">
            <h2 class="mb-0">
              {{ adminBranchState.branchInfo.name }}
            </h2>
            <p class="text-white">
              {{ adminBranchState.branchInfo.branch }}
            </p>
          </div>
        </div>
      </div>

      <div class="profile-header-nav">
        <b-navbar
          toggleable="md"
          type="light"
        >
          <b-navbar-toggle
            class="ml-auto"
            target="nav-text-collapse"
          >
            <b-img
              v-b-tooltip.hover.v-secondary
              title="顯示更多"
              :src="require('@/assets/images/pages/branch/header-manu.svg')"
              height="20"
            />
          </b-navbar-toggle>

          <b-collapse
            id="nav-text-collapse"
            is-nav
          >
            <b-tabs
              pills
              class="profile-tabs mt-1 mt-md-0"
              nav-class="mb-0"
              :value="adminBranchState.branchTab"
              @input="value => { $store.commit('admin-branch/UPDATE_BRANCH_TAB_STATE', value) }"
            >
              <b-tab>
                <template #title>
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="總覽"
                    :src="require('@/assets/images/pages/branch/tab-dashboard.svg')"
                    class="mr-md-50 mr-0"
                    height="20"
                  />
                  <span class="d-none d-md-block">總覽</span>
                </template>
              </b-tab>

              <b-tab v-if="checkAuthAbility('customer', 'API.Admin.Customer')">
                <template #title>
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="會員列表"
                    :src="require('@/assets/images/pages/branch/tab-customer.svg')"
                    class="mr-md-50 mr-0"
                    height="20"
                  />
                  <span class="d-none d-md-block">會員列表</span>
                </template>
              </b-tab>

              <b-tab v-if="checkAuthAbility('payment', 'API.Admin.Payment')">
                <template #title>
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="金流設定"
                    :src="require('@/assets/images/pages/branch/tab-payment.svg')"
                    class="mr-md-50 mr-0"
                    height="20"
                  />
                  <span class="d-none d-md-block">金流設定</span>
                </template>
              </b-tab>

              <b-tab v-if="checkAuthAbility('branch', 'API.Admin.Branch.Update')">
                <template #title>
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="設定"
                    :src="require('@/assets/images/pages/branch/tab-config.svg')"
                    class="mr-md-50 mr-0"
                    height="20"
                  />
                  <span class="d-none d-md-block">設定</span>
                </template>
              </b-tab>
            </b-tabs>

          </b-collapse>
        </b-navbar>
      </div>
    </b-card>

    <!-- 上傳圖片 -->
    <b-form-file
      ref="refInputEl"
      accept=".jpg, .png"
      :hidden="true"
      plain
      @change="loadImage($event)"
    />

    <!-- 圖片裁剪 -->
    <b-modal
      id="base-image-modal"
      centered
      :no-close-on-backdrop="close"
      :no-close-on-esc="close"
    >

      <template #modal-header>
        <h4>圖片裁剪</h4>
      </template>

      <div class="upload-example">
        <cropper
          ref="cropper"
          :src="image"
          :canvas="{
            minHeight: 0,
            minWidth: 0,
            maxHeight: 1920,
            maxWidth: 1920,
          }"
          :auto-zoom="false"
          :debounce="false"
          @change="onChange"
        />
      </div>

      <template #modal-footer>
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>

        <b-button
          size="sm"
          variant="primary"
          @click="uploadImage"
        >
          上傳
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BImg, BNavbar, BCardImg, VBTooltip, BSkeletonWrapper, BSkeletonImg,
  BFormFile, BModal, BButton, BNavbarToggle, BTab, BCollapse, BTabs,
} from 'bootstrap-vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import Ripple from 'vue-ripple-directive'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useBranchView } from '../useBranch'

export default {
  components: {
    BCard,
    BCardImg,
    BSkeletonImg,
    BFormFile,
    BModal,
    BButton,
    BNavbar,
    BCollapse,
    BTabs,
    BTab,
    BImg,
    BSkeletonWrapper,
    BNavbarToggle,

    VueLoadImage,
    Cropper,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      close: true,
      image: null,
      result: {
        coordinates: null,
        image: null,
      },
      imageType: 'icon',
    }
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
  methods: {
    // (觸發)圖片
    submitUploadImage(type) {
      if (type === 'banner') {
        const auth = this.checkAuthAbility('branch', 'API.Admin.Branch.Banner')
        if (!auth) return
      }

      if (type === 'icon') {
        const auth = this.checkAuthAbility('branch', 'API.Admin.Branch.Icon')
        if (!auth) return
      }
      this.imageType = type
      this.$refs.refInputEl.$el.click()
    },

    // 圖片預覽
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },

    // 圖片裁剪
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.image = canvas.toDataURL()
    },

    // 載入圖片
    loadImage(event) {
      const dirNameSplit = event.target.files[0].name.split('.')
      const type = dirNameSplit[dirNameSplit.length - 1]
      if (type !== 'jpg' && type !== 'png' && type !== 'PNG' && type !== 'JPG') return
      this.$bvModal.show('base-image-modal')
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.image = e.target.result }
        reader.readAsDataURL(input.files[0])
      }
    },

    // (上傳)圖片
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('data', blob)
          this.setBranchImageUpload({
            branch_id: this.adminBranchState.branchInfo.id,
            type: this.imageType,
            data: formData,
          })
            .then(response => {
              const image = response.data.data
              const resolveBranchInfo = {
                ...this.adminBranchState.branchInfo,
              }
              resolveBranchInfo[this.imageType] = image
              this.$store.commit('admin-branch/UPDATE_BRANCH_INFO_STATE', resolveBranchInfo)
              this.useAlertToast(response.data.success, response.data.message)
            })
            .catch(error => {
              this.useSwalAlertCenter(false, '上傳失敗!', error.response.data.message)
            })
        })
      }
      this.$refs.refInputEl.reset()
      this.image = null
      this.$bvModal.hide('base-image-modal')
      this.result.coordinates = null
    },

    // (刪除)圖片
    removeImage(type) {
      if (type === 'banner') {
        const auth = this.checkAuthAbility('branch', 'API.Admin.Branch.Banner.Clear')
        if (!auth) return
      }

      if (type === 'icon') {
        const auth = this.checkAuthAbility('branch', 'API.Admin.Branch.Icon.Clear')
        if (!auth) return
      }

      this.setBranchImageDelete({
        branch_id: this.adminBranchState.branchInfo.id,
        type,
      })
        .then(response => {
          const resolveBranchInfo = {
            ...this.adminBranchState.branchInfo,
          }
          resolveBranchInfo[type] = null
          this.$store.commit('admin-branch/UPDATE_BRANCH_INFO_STATE', resolveBranchInfo)
          this.useAlertToast(response.data.success, response.data.message)
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
        })
    },

    // 取消上傳
    cancel() {
      this.$bvModal.hide('base-image-modal')
      this.$refs.refInputEl.reset()
      this.result.coordinates = null
      this.image = null
    },
  },
  setup() {
    const {
      setBranchImageUpload,
      setBranchImageDelete,
    } = useBranchView()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      useAlertToast,
      useHttpCodeAlert,

      setBranchImageUpload,
      setBranchImageDelete,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.dark-layout {
  .profile-header {
    .profile-header-banner {
      background-color: $theme-dark-border-color;
    }
  }
}

.profile-header {
  .profile-header-banner {
    height: 300px;
    background-color: $border-color;
    position: relative;
    .banner-image {
      min-height: 300px;
    }
    .banner-image-mask {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
      .banner-icon {
        cursor: pointer;
      }
      .banner-image-edit {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #69bdf2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .banner-image-reset {
        position: absolute;
        top: 50px;
        right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #69bdf2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:hover {
      .banner-image-mask {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .profile-img {
    position: relative;
    .icon-iamge {
      position: absolute;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .profile-image-mask {
      display: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #00000080;
      user-select: none;
      .profile-icon {
        cursor: pointer;
      }
      .profile-image-reset {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #69bdf2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:hover {
      .profile-image-mask {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .profile-title {
    h2 {
      font-size: 2rem;
      color: white;
      font-weight: bolder;
      text-shadow: 0px 0px 5px #333;
    }
    p {
      color: white;
      text-shadow: 0px 0px 5px #333;
    }
  }
}
</style>
