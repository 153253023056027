<template>
  <!-- 頁面組件: (權限: 完成, 註解: 刪除, 優化: 無) -->
  <div v-if="adminBranchState">
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <base-setting
          :branch-data-item="adminBranchState.branchInfo"
        />
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <vaild-setting
          :branch-data-item="adminBranchState.branchInfo"
        />
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <seo-setting />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BaseSetting from './components/BaseSetting.vue'
import SeoSetting from './components/SeoSetting.vue'
import VaildSetting from './components/VaildSetting.vue'

export default {
  components: {
    BRow,
    BCol,
    BaseSetting,
    SeoSetting,
    VaildSetting,
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
  mounted() {
  },
}
</script>
