import { render, staticRenderFns } from "./BaseView.vue?vue&type=template&id=eb96b39a&scoped=true&"
import script from "./BaseView.vue?vue&type=script&lang=js&"
export * from "./BaseView.vue?vue&type=script&lang=js&"
import style0 from "./BaseView.vue?vue&type=style&index=0&id=eb96b39a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb96b39a",
  null
  
)

export default component.exports