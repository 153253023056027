<template>
  <!-- 頁面: 分站詳情(權限: 完成, 註解: 刪除, 優化: 無) -->
  <div id="user-profile">
    <div v-if="checkAuthAbility('branch', 'API.Admin.Branch.Id')">
      <branch-header />

      <div v-if="$store.state['admin-branch'].branchInfo">

        <component
          :is="tabContent"
        />
      </div>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-branch'}"
          >
            分站管理列表
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import { BLink, BAlert } from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import { useBranchView } from '../useBranch'
import BranchHeader from './BranchViewHeader.vue'
import BranchViewDashboard from './BranchViewDashboard.vue'
import BranchViewCustomer from './BranchViewCustomer.vue'
import BranchViewPayment from './BranchViewPayment.vue'
import BranchViewConfig from './BranchViewConfig.vue'
import useStoreModule from '../useStoreModule'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    BLink,
    BAlert,
    BranchHeader,
    BranchViewDashboard,
    BranchViewCustomer,
    BranchViewConfig,
    BranchViewPayment,
  },
  mixins: [usePublicFunction],
  data() {
    return {
    }
  },
  computed: {
    tabContent() {
      const tabIndex = this.$store.state['admin-branch'].branchTab
      if (tabIndex === 0) return 'branch-view-dashboard'
      if (tabIndex === 1) return 'branch-view-customer'
      if (tabIndex === 2) return 'branch-view-payment'
      if (tabIndex === 3) return 'branch-view-config'
      return 'branch-view-dashboard'
    },
  },
  setup() {
    const BRANCH_ADMIN_STORE_MODULE_NAME = 'admin-branch'

    if (!store.hasModule(BRANCH_ADMIN_STORE_MODULE_NAME)) store.registerModule(BRANCH_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(BRANCH_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_ADMIN_STORE_MODULE_NAME)
    })

    const branchID = router.currentRoute.params.id

    const {
      getBranchInfoData,
    } = useBranchView()

    const refreshBranchInfoData = () => {
      store.commit('admin-branch/UPDATE_BUSY_LOADING_STATE', true)
      getBranchInfoData({ branch_id: branchID }, () => {
        store.commit('admin-branch/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    refreshBranchInfoData()

    return {
      refreshBranchInfoData,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
